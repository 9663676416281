<template>
    <div v-if="pane" class="fixed inset-x-0 top-0 z-40 bg-white overflow-hidden flex flex-col grow w-full" :style="{ height: 'calc(100dvh - var(--mobile-nav-total-height))' }">
        <div class="border-b border-b-gray-100 shrink-0 px-5 pb-2 pt-2">
            <button type="button" title="Return" class="text-base hover:text-valence-grey-800/80" @click="clearPane">
                <span class="bi bi-chevron-left mr-1"></span>
                <span>{{ pane.title }}</span>
            </button>
        </div>
        <div class="grow overflow-hidden flex flex-col bg-[#DFDFDF]/20">
            <div v-if="loading" class="flex p-4 w-full justify-center">
                <div class="w-8 h-8 text-gray-400">
                    <LoadingSpinner />
                </div>
            </div>
            <div v-else-if="error">
                <p class="p-4 text-sm">Failed to load information. Please try again later.</p>
            </div>
            <div v-else-if="paneComponent" class="py-4 px-5 flex flex-col grow overflow-y-auto">
                <component :is="paneComponent.component" v-bind="paneComponent.props" />
            </div>
        </div>
    </div>

    <div
        class="md:hidden flex px-8 py-4 w-full justify-between fixed bottom-0 left-0 bg-white border-t border-gray-200 z-50"
        style="padding-bottom: max(1rem, env(safe-area-inset-bottom))"
    >
        <template v-for="item in panes" :key="item.id">
            <button
                type="button"
                class="text-base hover:font-medium hover:bg-valence-panehover flex flex-row justify-center items-center gap-5 leading-none rounded-lg p-2"
                @click="handlePaneClick(item)"
            >
                <component :is="item.icon" v-if="item.icon" class="mx-auto"></component>
                <span v-else class="font-semibold w-5">{{ item.content() }}</span>
            </button>
        </template>
    </div>
</template>

<script setup>
/**
 * Chris 1/17/24
 *
 * This is a temporary component to render the nav menu on mobile so we can get it out for a conference.
 *
 * It's intentionally got a lot of duplication with the `MainMenu` component, and things are not ideal. We have a redesign of the nav menu
 * coming in the new few weeks, so we should revisit this component then.
 */
import { router } from "@inertiajs/vue3";
import ChartUpRight from "~vue/icons/ChartUpRight.vue";
import Home from "~vue/icons/Home.vue";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import Plus from "~vue/icons/Plus.vue";
import RewindClock from "~vue/icons/RewindClock.vue";
import { isInIFrame } from "~vue/utils.js";
import { getFirstLastInitials } from "~vue/utils/stringUtils.js";
import { computed, h, inject, onMounted, onUnmounted, ref } from "vue";

import HistoryPane from "./HistoryPane.vue";
import JourneyPane from "./JourneyPane.vue";
import { MAIN_MENU_PANE } from "./MainMenu.vue";
import ProfilePane from "./ProfilePane.vue";

const globalProperties = inject("globalProperties");

const { emitter } = inject("globalProperties");

const userFirstName = globalProperties.$userDetails.value.first_name;
const userLastName = globalProperties.$userDetails.value.last_name;

const userInitials = computed(() => {
    let userFullName = userFirstName;
    if (userLastName) {
        userFullName += " " + userLastName;
    }
    return getFirstLastInitials(userFullName);
});

const PANE_DATA = {
    [MAIN_MENU_PANE.HOME]: {
        id: MAIN_MENU_PANE.HOME,
        title: "Home",
        icon: Home,
        url: "/",
    },
    [MAIN_MENU_PANE.HISTORY]: {
        id: MAIN_MENU_PANE.HISTORY,
        title: "Past Conversations",
        icon: RewindClock,
        component: HistoryPane,
    },
    [MAIN_MENU_PANE.NEW_CHAT]: {
        id: MAIN_MENU_PANE.NEW_CHAT,
        title: "New Chat",
        icon: Plus,
        url: "/coach/start/",
    },
    [MAIN_MENU_PANE.JOURNEYS]: {
        id: MAIN_MENU_PANE.JOURNEYS,
        title: "Journeys",
        icon: ChartUpRight,
        component: JourneyPane,
    },
    [MAIN_MENU_PANE.PROFILE]: {
        id: MAIN_MENU_PANE.PROFILE,
        title: "My Profile",
        icon: userInitials.value ? null : h("i", { class: "bi bi-person-circle text-xl leading-none" }),
        content: () => userInitials.value,
        component: ProfilePane,
    },
};

defineEmits(["openPane"]);

defineProps({
    enabledPanes: {
        type: Array,
        default: () => [],
    },
});

// Refs
const pane = ref(null);
const paneComponent = ref(null);
const error = ref(null);
const loading = ref(false);

// These panes are always enabled for the mobile menu, for now at least
const panes = [MAIN_MENU_PANE.HOME, MAIN_MENU_PANE.HISTORY, MAIN_MENU_PANE.NEW_CHAT, MAIN_MENU_PANE.JOURNEYS, MAIN_MENU_PANE.PROFILE].map((p) => PANE_DATA[p]);

const paneIsOpen = (item) => {
    return pane.value && pane.value.id === item.id;
};

const handlePaneClick = (item) => {
    if (item.url) {
        if (isInIFrame()) {
            window.location.href = !item.url.includes("client=teams") ? `${item.url}?client=teams` : item.url;
        } else {
            clearPane();
            router.visit(item.url);
        }
    } else {
        paneIsOpen(item) ? clearPane() : openPane(item);
    }
};

async function openPane(p) {
    pane.value = p;

    loading.value = true;
    const response = await fetch(`/navigation/?name=${p.id}`);
    const body = await response.json();
    loading.value = false;

    if (!response.ok) {
        error.value = body;
        return;
    }

    paneComponent.value = { component: p.component, props: body };
}

function clearPane() {
    pane.value = null;
}

onMounted(() => {
    emitter.on("sidebar-card-clicked", clearPane);
});

onUnmounted(() => {
    emitter.off("sidebar-card-clicked", clearPane);
});
</script>

<style>
:root {
    --mobile-nav-total-height: calc(4.5rem + env(safe-area-inset-bottom));
}

.mobile-pane-height {
    height: calc(100dvh - var(--mobile-nav-total-height));
}
</style>
